// ------------------------------------------
// SCROLLING NAV OUT OF VIEW MAKES IT STICK
// ------------------------------------------

// Vars
const scrollTimeoutDuration = 20;
const navWrapper = document.querySelector('#mainNav');
const navElem = navWrapper.querySelector('.nav');
const navHamburger = navWrapper.querySelector('.nav-hamburger');
let originNavWrapperPosY = navWrapper.offsetTop - 50;
let scrollTimeout = null;
let navSticky = false;
let navIsOpen = false;

// Makes scroll polling less costly
function pollScroll() {
  if(scrollTimeout) clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(checkScroll, scrollTimeoutDuration);
}

// Check if the nav scrolls out of view
function checkScroll() {
  const scrollTop = window.scrollY;

  // Don't bother on mobile
  if(window.innerWidth <= 540) {
    return;
  }

  if(scrollTop > originNavWrapperPosY && !navSticky) {
    document.body.classList.add('nav-is-sticky');
    navSticky = true;
  } else if (scrollTop <= originNavWrapperPosY && navSticky) {
    document.body.classList.remove('nav-is-sticky');
    navSticky = false;
  }
}

// Toggle menu open/close for mobile
function toggleMenu() {
  navIsOpen = !navIsOpen;

  navElem.classList[navIsOpen ? 'add' : 'remove']('is-open');
}

// Bind events
window.addEventListener('scroll', pollScroll);
navHamburger.addEventListener('click', toggleMenu);